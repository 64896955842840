<template>
  <section class="rankingCard">
    <p class="rankingCard__description">{{ description }}</p>
    <div class="rankingCard__contentCards" v-if="isLoading || items.length">
      <div class="rankingCard__rowCard" v-for="(item, idx) in items" :key="idx">
        <h2 class="rankingCard__numberCard">{{ idx + 1 }}</h2>
        <component class="rankingCard__card" :is="component" :isLoading="isLoading" :data="item"></component>
      </div>
    </div>
    <p class="rankingCard__withOutData" v-else>
      Sin datos.
    </p>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["description", "component"],
  components: {
    CardRankingRoom: () => import("./CardRankingRoom.vue"),
    CardRankingModel: () => import("./CardRankingModel.vue"),
  },
  data() {
    return {
      cardsLoading: 10,
      dicValues: {
        CardRankingRoom: "rankingRooms",
        CardRankingModel: "usersUpdated",
      },
      dicData: {
        CardRankingRoom: "rankingRooms",
        CardRankingModel: "rankingModels",
      },
    };
  },
  computed: {
    ...mapGetters("Occupation", ["loadingIn", "rankingRooms", "rankingModels"]),
    ...mapGetters("connectedUsers", ["getUserMapIndex"]),
    usersUpdated() {
      const usersMapIndex = this.getUserMapIndex;

      return this.rankingModels.map((user) => {
        const userFind = usersMapIndex[user._id];

        const isActive = !!userFind?.isActive;
        const isOnline = !!userFind?.isOnline;

        return { ...user, isActive, isOnline };
      });
    },
    isLoading() {
      return this.loadingIn(this.dicData[this.component]);
    },
    items() {
      return this.isLoading ? this.cardsLoading : this[this.dicValues[this.component]];
    },
  },
  beforeDestroy() {
    this.$store.commit(`Occupation/changeLoadingIn`, { name: this.dicData[this.component], value: true });
  },
};
</script>

<style lang="scss">
.rankingCard {
  width: 100%;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px #00000040;
  &__contentCards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: auto;
    gap: 10px;
    padding: 15px 20px 20px 20px;
  }
  &__description {
    padding: 20px;
    font-size: 17px;
    font-weight: 600;
    border-bottom: 2px solid #ececec;
  }
  &__rowCard {
    @include Flex(row);
    width: 100%;
    gap: 10px;
  }
  &__numberCard {
    width: 22px;
    font-size: 27px;
    text-align: center;
  }
  &__card {
    width: calc(100% - 22px);
  }
  &__withOutData {
    padding: 20px;
    text-align: center;
  }
  @media screen and (min-width: $tablet_width) {
    &__contentCards {
      grid-template-columns: repeat(2, minmax(300px, 1fr));
    }
  }
}
</style>
